
import {Component, Prop, Vue} from 'vue-facing-decorator';
import * as API from "@/api";
import {EntryTicket, EventData, OrderData, Seat, SegmentsDiscounts} from "@/@types/api";
import SvgPanZoom from "svg-pan-zoom";
import {closeWidget, getOriginDomain, redirect} from "@/utils";
import Point = SvgPanZoom.Point;
import {originsConf} from "@/originsConf";
import {setAuthLogin} from "@/api";
import {ymInitByID} from "@/ym";

// todo добавить возможность завершить заказ после закрытия виджета

// todo кнопка вернуться в магазин надо брать не из мерчанта, а с origin родителя, т.к. веб-витрины могут быть разные а мерчант один

//import {tr} from "vuetify/locale";
//import {getPromocodeData} from "@/api";
// import {RTDB} from "@/firebase";

type ValidateRules = Array<(value: any) => any>

@Component({
  components: {}
})
export default class TCEventMap extends Vue {

  isIframe() {
    return window.parent != window.self
  }

  closeDialog = false

  close = closeWidget

  //selectedLimit = 10

  orderData: OrderData | null = null
  payRedirectWindow = false // если false значит браузер блокирнул

  primaryColor = 'blue-lighten-1'

  conditionsAgree = false
  conditionsAlarm = false

  // загрузка общая
  pending = true

  dialogEntryTickets = false // если со схемы кликнули на входной сегмент - показать диалог выбора
  dialogEntryTicketsSegment = '' // если указан, фильтруем

  dialogPromo = false
  dialogPromoPending = false
  promoCode = ''
  promoCodeError = ''
  segmentsDiscounts: SegmentsDiscounts | null = null

  getSelectedSegments(): number[] {
    const segments: Set<number> = new Set
    this.selected.entry.forEach(v => segments.add(v.segmentId))
    this.selected.seats.forEach(seatId => {
      if (this.eventData?.seats) segments.add(this.eventData?.seats[seatId].segmentId)
    })
    return Array.from(segments)
  }

  async applyPromoCode() {

    this.promoCode = this.promoCode.trim()

    this.dialogPromoPending = true

    API.getPromocodeData(this.eventId, this.promoCode, this.getSelectedSegments())
        .then(discount => {

          if (discount) {
            this.segmentsDiscounts = discount
            this.promoCodeError = ''
            this.dialogPromo = false
          } else
            this.promoCodeError = 'Промокод недействительный'

        })

        .catch((e) => {
          this.promoCodeError = 'Не удалось, попробуйте позже'
          console.log(e)
        })

        .finally(() => this.dialogPromoPending = false)

  }

  mobile = false

  onResize() {
    this.mobile = window.innerWidth < 600 // тоже самое значение в widget.js поставить
    //this.mobile = useDisplay().mobile.value
  }

  getWindowHeight() {
    return window.innerHeight
  }

  beforeDestroy() {
    if (typeof window !== 'undefined')
      window.removeEventListener('message', this.onParentMessage)
  }

  showEntrySegmentDialog(segmentId = '') {

    this.dialogEntryTickets = true

    if (this.eventData && this.eventData.entryTickets)
      this.dialogEntryTicketsSegment = segmentId
  }

  formatTimer(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Форматируем минуты и секунды для отображения в формате 00:00
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  async createOrder(sbp: boolean) {

    if (this.eventData?.conditionsUrl && !this.conditionsAgree) {
      this.conditionsAlarm = true
      return;
    }

    this.step = 4

    API.createOnlineOrder({
      eventId: this.eventId,
      seats: this.selected.seats,
      entryTickets: this.selected.entry,
      name: this.client.name,
      tel: this.client.tel.trim() ? "7" + this.client.tel.trim() : "",
      email: this.client.email,
      birthDate: this.client.birthDate,
      sum: this.getTotalSum(),
      ymClientId: Array.from(window.ymData.ids).join(',') + " " + window.ymData.clientId,
      promoCode: this.promoCode,
      comment: ""
    }, sbp)
        .then(orderData => {

          // тут надо передавать заказ наружу, что бы скрипт виджета его отправил на сервер при закрытии
          window.parent.postMessage(`chTicketWidget.payId=${orderData.payId}`, '*')
          window.parent.postMessage(`chTicketWidget.orderId=${orderData.orderId}`, '*')

          this.orderData = orderData

          // для таймера сколько секунд осталось
          if (this.orderData?.paySeconds)
            setInterval(() => {
              if (this.orderData?.paySeconds) {
                this.orderData.paySeconds = this.orderData.paySeconds - 1
                if (this.orderData.paySeconds < 0)
                  this.orderData.paySeconds = 0
              }
            }, 1000)

          if (this.orderData.payUrl == "success") {
            //this.$router.push('/success')
            this.step = 6
          } else {
            this.step = 5

            this.ymAdd()

            // Если режим админа тогда показываем QR
            // if (this.$route.query['admin'])
            //   this.$router.push(`/qr?url=${encodeURIComponent(orderData.payUrl)}`)
            // // Если клиент, тогда перенаправляем на процессинг
            // else

            // работает - переделали на кнопку и показ таймера
            setTimeout(() => {
              if (this.orderData?.payUrl)
                this.payRedirectWindow = redirect(this.orderData.payUrl)
              if (this.payRedirectWindow)
                this.close()
            }, 100)

          }
          //this.step = 6

        })

        .catch((e) => {

          console.log(e)

          //this.$router.push('/fail?text=' + encodeURIComponent('Нам не удалось сформировать Ваш заказ. Пожалуйста повторите попытку.'))//this.step = 7
          this.showError('Нам не удалось сформировать Ваш заказ. Пожалуйста повторите позже.')


        })


  }

  dialogSellerInfo = false

  dialogPaymentRedirect = true

  getSelectedSeatsData(): Seat[] {
    return Object.values(this.eventData?.seats ?? {}).filter(s => this.selected.seats.includes(s.id))
  }

  getSegmentDiscountK(segmentId: number): number {
    return this.segmentsDiscounts && this.segmentsDiscounts[segmentId] ? (100 - this.segmentsDiscounts[segmentId]) * 0.01 : 1
  }

  getTotalSum() {

    let sum = 0

    this.getSelectedSeatsData().forEach(seat =>
        sum += Math.round(seat.price * this.getSegmentDiscountK(seat.segmentId))
    )

    this.selected.entry.forEach(entry =>
        sum += Math.round(entry.price * this.getSegmentDiscountK(entry.segmentId))
    )

    return sum
  }

  getTotalCount() {
    return this.getSelectedSeatsData().length + this.selected.entry.length
  }

  onInputBirthDate(event: InputEvent) {

    // прибавили символ
    if (event.data) {
      if (this.client.birthDate.length === 2 || this.client.birthDate.length === 5)
        this.client.birthDate += '.'
      else if (this.client.birthDate.length > 10)
        this.client.birthDate = this.client.birthDate.slice(0, 10)
    }
    // // удалили символ
    else
      this.client.birthDate = ''

  }

  async onSubmitClientForm() {

    const {valid} = await (this.$refs.clientForm as any).validate()

    if (valid) {
      this.step = 3

      if (this.$route.query['promocode'] as string){
        this.promoCode = this.$route.query['promocode'] as string
        this.applyPromoCode()
      }

    }

  }

  client = {
    name: '',
    email: '',
    tel: '',
    birthDate: ''
  }

  nameRules: ValidateRules = []

  emailRules: ValidateRules = []

  telRules: ValidateRules = []

  dateRules: ValidateRules = []

  @Prop
  eventId = ''

  svgHtml = ''

  isMapPanned = false

  step = 1

  hoveredCardSeatId: string | undefined

  selected: {
    seats: string[]
    entry: { id: number, segmentId: number, segmentName: string, price: number }[]
  } = {
    seats: [],
    entry: []
  }

  seatOver: Seat | null = null

  seatTooltipStyle = {
    top: '',
    left: ''
  }

  eventData?: EventData | null = undefined

  pricesValues: number[] = [] // все возможные цены и сидячие и входные
  pricesColors: string[] = []
  pricesForLegend: number[] = [] // фильтр которые места раскрашены на схеме

  updatePricesColors() {
    // svg должна быть уже в DOM что бы считать цвета цен
    this.pricesColors = []
    for (let i = 1; i <= this.pricesValues.length; i++) {
      const element = this.svgMapElement?.getElementsByClassName('price-' + i)[0]
      this.pricesColors.push(element ? window.getComputedStyle(element).getPropertyValue('fill') : '')
    }
  }

  onClickTicketTab(seatId: string) {

    if (!this.svgPanZoom || !this.svgMapElement) return;

    // если нет в выбранных сидячих - значит кликали на крестик или это входной билет
    if (!this.selected.seats.includes(seatId)) return;

    const seatElement = this.svgMapElement.getElementById(seatId.toString())
    if (!seatElement) return;

    const x = Number(seatElement.getAttribute('cx') ?? 0)
    const y = Number(seatElement.getAttribute('cy') ?? 0)

    this.setPanToCoords(x, y, 2.2)
  }

  onHoverSelected(seatId: string) {
    // мобильные не могут наводить
    if (!this.svgMapElement || this.mobile) return;

    this.hoveredCardSeatId = seatId
    this.svgMapElement.getElementById(seatId.toString())?.classList.add('seat_hover')
  }

  onHoverPrice(price: number) {
    // передаем цену, т.к. цены легенды могут быть меньше чем все возможные цены, которые включают цены на места отсутствующие на схеме
    if (!this.svgMapElement || this.mobile) return;
    const elements = this.svgMapElement.getElementsByClassName('price-' + (this.pricesValues.indexOf(price) + 1))
    for (const element of elements)
      element.classList.add('seat_hover')
  }

  onLeavePrice(price: number) {
    if (!this.svgMapElement || this.mobile) return;
    const elements = this.svgMapElement.getElementsByClassName('price-' + (this.pricesValues.indexOf(price) + 1))
    for (const element of elements)
      element.classList.remove('seat_hover')
  }

  setPanToCoords(x: number, y: number, zoom = 2.2) {

    if (!this.svgPanZoom) return;

    if (this.svgPanZoom.getZoom() == 1)
      this.svgPanZoom.zoom(zoom)

    this.svgPanZoom.pan({x: 0, y: 0})
    const sizes = this.svgPanZoom.getSizes()
    const realZoom = sizes.realZoom

    this.svgPanZoom.pan({
      x: -(x * realZoom) + (sizes.width / 2),
      y: -(y * realZoom) + (sizes.height / 2)
    })

  }

  onClickPrice(price: number) {
    if (!this.svgMapElement) return;
    // считаем центр XY всех элементов
    const elements = this.svgMapElement.getElementsByClassName('price-' + (this.pricesValues.indexOf(price) + 1))
    if (!elements.length) return;

    let xAv = 0;
    let yAv = 0;
    for (const element of elements)
      if (element instanceof SVGGeometryElement) {
        const bbox = element.getBBox()
        xAv += bbox.x + bbox.width / 2
        yAv += bbox.y + bbox.height / 2

        // старый способ не работает для path
      // if (element.hasAttribute('cx')) {
      //   xAv += +(element.getAttribute('cx') ?? 0)
      //   yAv += +(element.getAttribute('cy') ?? 0)
      // }

    }
    xAv = xAv / elements.length
    yAv = yAv / elements.length

    this.setPanToCoords(xAv, yAv, 1.6)

  }

  onLeaveSelected() {
    if (this.hoveredCardSeatId && this.svgMapElement)
      this.svgMapElement.getElementById(this.hoveredCardSeatId.toString())?.classList.remove('seat_hover')
    this.hoveredCardSeatId = undefined
  }

  // для определения когда в dom загрузился maps
  firstUpdate = true

  updated() {

    // Это инициализация когда SVG загрузился - перенес в коллбек loadSVG
    // if (this.firstUpdate && document.getElementById('svgMapElement')) {
    //   this.firstUpdate = false
    //   this.initPanZoom()
    //   this.renderSeats()
    // }

  }

  renderSeats() {

    if (!this.svgMapElement || !this.eventData) {
      console.error(new Error('Схема не готова или нет данных'))
      return;
    }

    //const elements = Array.from(this.svgMapElement.getElementsByClassName('seat') as HTMLCollectionOf<HTMLElement>) //as HTMLElement[])
    if (this.eventData.seats) {
      const elementsSeats = this.svgMapElement.querySelectorAll<HTMLElement>('.seat')
      for (const element of elementsSeats) {

        // удаляем стиль ценовой группы
        element.classList.forEach(name => {
          if (name.includes('price-')) element.classList.remove(name)
        })

        const seat = this.eventData.seats[element.id]

        //console.log('seat ',element.id, !!seat)

        if (seat) {

          element.classList.add('seat_free');

          // element.style.fill = this.colorPalette.get(seat.price) ?? 'yellow';
          // element.style.stroke = this.colorPalette.get(seat.price) ?? 'yellow';
          element.classList.add('price-' + (this.pricesValues.indexOf(seat.price) + 1))

        }
        // Место не в продаже. Оставляем только класс seat
        else {
          element.classList.remove('seat_free')

          // element.style.removeProperty('fill')
          // element.style.removeProperty('stroke')

          // если место было выбрано - снимаем

          this.removeSeatFromSelected(element.id)

        }

      }
    }

    if (this.eventData.entryTickets?.length) {

      const elementEntryTicketsAll = this.svgMapElement.getElementById('EntryTicketsAll');
      // выбираем цену первого входного билета, этим цветом и раскрасится весь сегмент на схеме
      if (elementEntryTicketsAll)
        elementEntryTicketsAll.classList.add('price-' + (this.pricesValues.indexOf(this.eventData.entryTickets[0].price) + 1))

      // Раскрашиваем сегмент в цвет цены
      const elementsSegment = this.svgMapElement.querySelectorAll<HTMLElement>('.entryTicketsAll, .entryTicketsAll_label')
      for (const element of elementsSegment) {

        // удаляем стиль ценовой группы
        element.classList.forEach(name => {
          if (name.includes('price-')) element.classList.remove(name)
        })


        const entryTicket = this.eventData.entryTickets.find((v) => v.segmentId == parseInt(element.id))
        if (entryTicket)
          element.classList.add('price-' + (this.pricesValues.indexOf(entryTicket.price) + 1))

      }


    }

    // Object.values(this.eventData.seats)?.forEach(seat => {
    //
    //   const element = this.svgMapElement.getElementById(seat.id.toString())
    //
    //   if (element) {
    //     element.classList.add('seat_free');
    //     element.style.fill = this.colorPalette.get(seat.price) ?? 'white';
    //     element.style.stroke = this.colorPalette.get(seat.price) ?? 'white';
    //
    //   } else {
    //     // в 1с с запасом заведено, поэтому часто срабатывает
    //     //console.error(new Error('не найден элемент места по id '+seat.seatId))
    //     return;
    //   }
    //
    // })

    // Легену цен отрисовываем на основе раскраски мест схемы
    this.pricesForLegend = this.pricesValues.slice().filter((price, i) => {
      //console.log(price, 'price-' + (i+1), !!this.svgMapElement?.getElementsByClassName('price-' + (i+1)).length)
      return !!this.svgMapElement?.getElementsByClassName('price-' + (i + 1))?.length
    })

  }

  zoomPlusDisabled = false
  zoomMinusDisabled = true

  zoom(zoomIn: boolean) {

    if (!this.svgMapElement || !this.svgPanZoom) return;

    if (zoomIn)
      this.svgPanZoom.zoomIn()
    else
      this.svgPanZoom.zoomOut()

    const zoom = ~~(this.svgPanZoom.getZoom() + 0.1)

    this.zoomMinusDisabled = zoom == 1
    this.zoomPlusDisabled = zoom == 8 // должно совпадать с конфигом инициализации

  }

  formatMoney(amount, digits = 0) {
    // Преобразуем число в строку и разделяем целую и десятичную части
    let parts = amount.toFixed(digits).toString().split('.');

    // Форматируем целую часть, добавляя разделители тысяч
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u2009');  // 2009 тонкий , u00A0 обычный

    // Возвращаем отформатированную строку
    return parts.join('.') + '\xa0₽';
  }

  svgMapElement: SVGSVGElement | null = null
  svgPanZoom: SvgPanZoom.Instance | null = null

  initPanZoom() {

    const initSvgTooltip = (svgMapElement) => {

      // Функция для обработки события наведения на элемент
      const showTooltip = (event) => {

        if (!this.svgPanZoom) return;

        const svgElement = event.target as HTMLElement
        //svgElement.classList.add('seat_over')

        // Позиция подсказки рядом с элементом
        const svgRect = svgElement.getBoundingClientRect()

        this.seatTooltipStyle.top = this.svgPanZoom.getZoom() * 3 + svgRect.top + 10 + 'px'
        this.seatTooltipStyle.left = this.svgPanZoom.getZoom() * 3 + (svgRect.left < 820 ? (svgRect.left + 10) : (svgRect.left - 105)) + 'px'

        // Задаем место на которое навели - подсказка появится
        //this.seatOver = this.eventData?.seats?.find(v => v.seatId === Number(svgElement.id))
        this.seatOver = (this.eventData?.seats || {})[svgElement.id]

      }

      // Функция для обработки события ухода курсора с элемента
      const hideTooltip = () => {
        //const svgElement = event.target as HTMLElement
        //svgElement.classList.remove('seat_over')
        this.seatOver = null
        //tooltip.style.display = 'none'
      }

      const svgElements = svgMapElement.getElementsByClassName('seat')
      for (let i = 0; i < svgElements.length; i++) {
        const svgElement = svgElements[i]
        svgElement.addEventListener('mouseenter', showTooltip)
        svgElement.addEventListener('mouseleave', hideTooltip)
      }

    }

    this.svgMapElement = (document.getElementById('svgMapElement') as unknown as SVGSVGElement)

    if (this.svgMapElement) {

      this.svgPanZoom = SvgPanZoom(this.svgMapElement, {
        // viewportSelector: '.seatScheme',
        mouseWheelZoomEnabled: false,
        dblClickZoomEnabled: false,
        center: true,
        fit: true,
        controlIconsEnabled: false,
        zoomScaleSensitivity: 2,
        maxZoom: 8, //parseInt(this.svgMapElement.getAttribute('tc-max-zoom') ?? '4'),
        minZoom: 1,
        panEnabled: true,
        preventMouseEventsDefault: true,
        // beforePan: this.beforePan.bind(this),

        //onPan: this.onPan.bind(this),
      })

      initSvgTooltip(this.svgMapElement)
    }

  }

  // lastPanPoint = {x: 0, y: 0}
  // beforePan(point){
  //   console.log(point)
  //   this.lastPanPoint = point
  // }

  async loadSVG(name: string) {

    // todo Учесть тут может быть исключение!

    const response = await fetch(`maps/${name}.svg`)

    if (response.ok) {
      this.svgHtml = await response.text()
      // рендер сработает в событии update this.renderSeats()

    } else {
      console.error(response)
      //this.$router.push('/fail')
      this.showError()
    }

  }

  errorText = ''

  showError(text = "") {
    this.errorText = text
    this.step = 7
  }

  ymOnPayment() {

    if (!this.eventData || !window.ym) return;
    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "purchase": {
          "actionField": {
            "id": ""
          },
          "products": [
            {
              "id": this.eventData.id,
              "name": this.eventData.name,
              "price": this.getTotalSum(),
              "brand": "",
              "category": "",
              "variant": "",
              "quantity": this.getTotalCount(),
              "list": "",
              "position": 1
            }
          ]
        }
      }
    })
  }

  ymAdd() {
    if (!this.eventData || !window.ym) return;

    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "add": {
          "products": this.getSelectedSeatsData().map((v) => {
            return {
              "id": this.eventData?.id,
              "name": this.eventData?.name,
              "price": v.price,
              //"brand": "Яндекс / Яndex",
              "category": v.segmentName,
              "quantity": 1,
              //"list": "Выдача категории",
              //"position": 2
            }

          })
        }
      }
    })
  }

  ymRemove() {
    if (!this.eventData || !window.ym) return;
    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "remove": {
          "products": this.getSelectedSeatsData().map((v) => {
            return {
              "id": this.eventData?.id,
              "name": this.eventData?.name,
              "price": v.price,
              //"brand": "Яндекс / Яndex",
              "category": v.segmentName,
              "quantity": 1,
              //"list": "Выдача категории",
              //"position": 2
            }

          })
        }
      }
    });
  }

  ymOnOpen() {

    if (!this.eventData || !window.ym) return;
    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "detail": {
          "products": [
            {
              "id": this.eventData.id,
              "name": this.eventData.name,
              "price": 0,
              "brand": "",
              "category": "",
              "variant": "",
              "list": "",
              "position": 1
            }
          ]
        }
      }
    })

  }

  async created() {

    // можно вынести в App
    // пробуем получить родительский домен...
    let originDomain = getOriginDomain(this.$route.query)
    setAuthLogin(originDomain)

    this.telRules.push(value => {

      if (this.eventData?.requireTel && !value.trim()) return false;
      if (value.trim() && !/^\d+$/.test(value)) return 'Некорректный формат'

      return true

    })
    this.dateRules.push(value => {

      //if (/^\d{11}$/.test(value)) return true
      //this.client.error = !value// !/^\d+$/.test(value)

      if (this.eventData?.requireBirthDate && !value.trim()) return false;

      // 25.12.2023
      if (value.trim() && !value.match(/^(0[1-9]|[12][0-9]|3[01])\.(0[0-9]|1[0-2])\.\d{4}$/)) return 'Некорректная дата'

      return true
    },)

    this.emailRules.push(value => {
      if (!value.trim()) return false;

      if (!/.+@.+\..+/.test(value))
        return 'E-mail некорректный'

      return true
    },)

    this.nameRules.push(value => {
      if (!value.trim()) return false;
      if (!/^[a-zA-Zа-яА-Я\s']+$/.test(value)) return 'Неверные символы'
      if (value?.length > 32) return 'Имя слишком длинное'

      return true
    })


    if (!this.eventId) {
      console.error('eventId is undefined on created hook')
      return;
    }

    const isCssColor = (strColor) => {
      const s = new Option().style;
      s.color = strColor;
      return s.color !== '';
    }

    this.primaryColor = 'red-darken-2'
    const color = this.$route.query['color']
    if (color && typeof color === 'string')
      this.primaryColor = isCssColor('#' + color) ? '#' + color : color
    else if (originsConf[originDomain]) {
      const color = originsConf[originDomain].color
      if (color)
        this.primaryColor = color
    }


    this.pending = true
    this.eventData = await API.getEvent(this.eventId, this.$route.query['debug']?.toString())
    this.pending = false

    // todo условие на есть данные или нет переписать по нормальному

    if (this.eventData?.ymId) ymInitByID(this.eventData.ymId)

    if (this.eventData) this.ymOnOpen()

    this.pricesValues = [
      ...new Set(Object.values(this.eventData?.seats ?? {}).map(seat => seat.price)),
      ...new Set(Object.values(this.eventData?.entryTickets ?? {}).map(entry => entry.price)),
    ].sort((a, b) => b - a)

    if (this.eventData?.svgMapName) {

      this.loadSVG(this.eventData.svgMapName)
          .then(() => {

            this.firstUpdate = false
            this.initPanZoom()
            this.renderSeats()

            this.updatePricesColors()

          })
          .catch(e => console.error(e))
    } else {
      // Без схемы виджет должен работать и выводить входные билеты. Если и их нет - пишет что билетов нет извините
      //console.error('svgMapName не определена')
      //this.$router.push('/fail')
      //return;
    }

    // Вариант через FR но как его своевременно обновлять?
    //let firstResponse = true;
    // RTDB.onValue(RTDB.getRef(`/events/${this.eventId}`), async (snap) => {
    //   const result = snap.val() as FREventResult | undefined
    //
    //   // if (!result?.data || !result?.timestamp) {
    //   //   API.getEvent(this.eventId)
    //   //   return;
    //   // }
    //
    //   // ПЕРВОЕ ПОЛУЧЕНИЕ ДАННЫХ
    //   if (firstResponse) {
    //     firstResponse = false
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //
    //       const cacheTimeout = ~~(Date.now() * 0.001) - result.timestamp
    //       // console.log(~~(Date.now() * 0.001))
    //       // console.log(result.timestamp)
    //       console.log('EventData FR cache timeout:', cacheTimeout)
    //
    //       // todo если схема очень протухла а прямой запрос не удался - что делать?
    //
    //       if (cacheTimeout > 60 * 2) {
    //         console.log('Old cache, call api...')
    //         API.getEvent(this.eventId)
    //             .catch(e => console.error(e))
    //       }
    //
    //     } else
    //       this.eventData = await API.getEvent(this.eventId)
    //
    //     // Данные не получены не из FR не из Сервера
    //     if (!this.eventData) {
    //       console.error('eventData отсутствует по этому мероприятию')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //     if (this.eventData?.svgMapName)
    //       this.loadSVG(this.eventData.svgMapName)
    //           .catch(e => console.error(e))
    //     else {
    //       console.error('svgMapName не определена')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //
    //   } else {
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //       this.renderSeats()
    //     } else {
    //       console.error('Получены пустые данные event')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //   }
    //
    //
    // })

  }

  onParentMessage(event) {

    if (event.data === 'chTicketWidget.clickOutside')
        // Закрываем без вопросов если выбранных билетов нет
      if (this.getTotalCount())
        this.closeDialog = true
      else
        this.close()
  }

  async mounted() {

    // this.svgHtml - будет undefined, получение определяем в update()

    window.addEventListener('message', this.onParentMessage)

    window.parent.postMessage('chTicketWidget.API_URL=' + API.API_URL, '*')

    this.onResize()

  }

  addSeatToSelected(id: string) {

    if (!this.svgMapElement) return;

    // на моб версии первый клик делаем зум
    if (this.mobile && this.zoomMinusDisabled) return;

    if (this.getTotalCount() >= this.getTicketsLimit()) return;

    this.segmentsDiscounts = null // сбрасываем промокод

    this.selected.seats.push(id)

    const element = this.svgMapElement.getElementById(id.toString())
    if (element) {
      //element.classList.remove("seat_free")
      element.classList.add("seat_selected")
    }

    const elementRow = this.svgMapElement.getElementById(id.toString().substring(0, 6))
    if (elementRow) {
      //element.classList.remove("seat_free")
      elementRow.classList.add("row_selected")
    }

  }

  addEntryToSelected(entry: EntryTicket) {

    this.segmentsDiscounts = null // сбрасываем промокод

    if (
        this.getTotalCount() < this.getTicketsLimit()
        && (entry.countAvailable - this.getEntrySelectedCount(entry.segmentId, entry.price)) > 0
    )
      this.selected.entry.push({
        id: entry.id, segmentId: entry.segmentId, segmentName: entry.segmentName, price: entry.price
      })

    this.updateEntrySegmentsVisual()

  }

  updateEntrySegmentsVisual() {
    const elements = this.svgMapElement?.getElementsByClassName("entryTicketsAll")
    if (elements)
      for (const element of elements)
        if (this.selected.entry.length)
          element.classList.add("entryTicketsAll_selected")
        else
          element.classList.remove("entryTicketsAll_selected")
  }

  getTicketsLimit() {
    return this.eventData?.ticketsLimit || 10
  }

  delEntryToSelected(id: number) {
    const element = this.selected.entry.find(entry => entry.id === id)
    if (element != undefined)
      this.selected.entry.splice(this.selected.entry.indexOf(element), 1)
    this.updateEntrySegmentsVisual()
  }

  getEntrySelectedCount(segmentId: number, price: number) {
    return this.selected.entry.filter(entry => entry.segmentId === segmentId && entry.price === price).length
  }

  removeSeatFromSelected(id: string) {

    if (!this.svgMapElement) return;

    if (this.selected.seats.includes(id))
      this.selected.seats.splice(this.selected.seats.indexOf(id), 1)
    else {
      for (const entry of this.selected.entry) {
        if (entry.id.toString() === id) {
          this.selected.entry.splice(this.selected.entry.indexOf(entry), 1)
          this.updateEntrySegmentsVisual()
          break;
        }
      }
    }

    const element = this.svgMapElement.getElementById(id.toString())
    if (element) {
      //element.classList.add("seat_free") // todo когда схема будет обновляться в онлайн, тут проверять может не свободно будет
      element.classList.remove("seat_selected")
      element.classList.remove("seat_hover")
    }


  }

  panStartPoint: Point | null | undefined = null

  onSvgMapTouchStart() {
    this.isMapPanned = true
    this.panStartPoint = this.svgPanZoom?.getPan()
  }

  onSvgMapMouseDown(event: MouseEvent) {
    this.isMapPanned = true
    this.panStartPoint = this.svgPanZoom?.getPan()

    event.preventDefault()
    event.stopPropagation()

    return false

  }

  // в onSvgMapClick это делаем
  // onSvgMapTouchEnd(){
  //   console.log('onSvgMapTouchEnd')
  //   this.isMapPanned = false
  //
  // }

  onSvgMapMouseUp() {
    this.isMapPanned = false
  }

  onSvgMapClick(event: MouseEvent) {

    if (!this.svgMapElement || !this.eventData?.seats) return;

    // ВАЖНО! Нужно отсеять события перетаскивания, убедиться что это был клик по точке, а не захват что бы двигать схему!
    if (JSON.stringify(this.panStartPoint) != JSON.stringify(this.svgPanZoom?.getPan()))
      return;

    // // Если первый уровень - приближаем
    // if (this.svgPanZoom?.getZoom() === 1) {
    //   console.log(event)
    //   this.svgPanZoom.zoom(2)
    //   this.svgPanZoom.enablePan()
    //   //this.svgPanZoom.pan({})
    //   return;
    // }


    this.isMapPanned = false

    // // Для мобилой всегда увеличивает если схема мелка
    // if (this.$vuetify.display.mobile && SvgPanZoom(this.svgMapElement).getZoom() <= 1.1) {
    //   this.zoom(true)
    //   return;
    // }

    const target = event.target as Element

    // Если элемент место
    if (target.classList.contains('seat')) {

      //const seat = this.eventData?.seats?.find(seat => seat.id === Number(event.target.id))
      const seat = this.eventData.seats[target.id ?? '']

      // Если место в продаже (свободно)
      if (seat)
          // Если не выбрано -> выбираем
        if (!this.selected.seats.includes(seat.id))
          this.addSeatToSelected(seat.id)
        else
          this.removeSeatFromSelected(seat.id)

      this.$forceUpdate()
    }

    // Если кликнули по сегменту - ложные срабатывания!
    else if (target.classList.contains('entryTicketsAll')) {

      //this.showEntrySegmentDialog(event.target.id)
      this.showEntrySegmentDialog()

    }


  }

  calcTransforms() {

    const roundCoordinates = (svgElement) => {
      const elements = svgElement.querySelectorAll('[x], [y]'); // Найти все элементы с атрибутами x и y

      elements.forEach((el) => {
        // Получить x и y
        const x = parseFloat(el.getAttribute('x'));
        const y = parseFloat(el.getAttribute('y'));

        // Округлить значения до 2 знаков после запятой
        if (!isNaN(x)) {
          el.setAttribute('x', x.toFixed(1));
        }
        if (!isNaN(y)) {
          el.setAttribute('y', y.toFixed(1));
        }
      });
    }

    const svg = document.createElement('svg')
    svg.innerHTML = this.svgHtml
    roundCoordinates(svg)

    console.log(svg.outerHTML)


  }

  turnXY() {

    const svg = document.createElement('svg')
    svg.innerHTML = this.svgHtml

    svg.querySelectorAll('[x], [y], [cx], [cy]').forEach((el) => {

      const setAttribute = (el, name) => {
        const value = parseFloat(el.getAttribute(name) ?? '')
        if (!isNaN(value))
          el.setAttribute(name, (-1 * value).toString())
      }

      setAttribute(el, 'x')
      setAttribute(el, 'cx')
      setAttribute(el, 'y')
      setAttribute(el, 'cy')

    });

    console.log(svg.outerHTML)

  }

  async setSeatElementsId() {

    if (!this.eventData || !this.svgHtml) return;

    for (const id in this.eventData?.seats) {

      const idOrig = this.eventData.seats[id]['idOrig']
      if (idOrig)
        this.svgHtml = this.svgHtml.replace('id="_' + idOrig + '"', 'id="' + id + '"')

    }

    // Проверка на дубли
    function findDuplicateIdsInSVGText(svgText) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const elements = svgDoc.querySelectorAll('[id]');
      const ids = Array.from(elements).map(el => el.id);
      const idCounts = ids.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {});
      return Object.entries(idCounts)
          .filter(([id, count]) => (count as number) > 1)
          .map(([id]) => id);
    }

    console.warn('дубли id ' + findDuplicateIdsInSVGText(this.svgHtml));

    //let html = this.svgMapElement.getElementById('svgMapElement')?.outerHTML ?? ''
    this.svgHtml.replace(/><\/path/g, '/')
    this.svgHtml.replace(/><\/circle/g, '/')
    this.svgHtml.replace(/><\/rect/g, '/')

    console.log(this.svgHtml)

  }

}

