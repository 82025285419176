import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, setBlockTracking as _setBlockTracking, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  class: "d-flex pa-4 pl-6 pb-0 flex-wrap justify-sm-space-between text-left position-relative",
  style: {"box-shadow":"white 0 0 20px 20px"}
}
const _hoisted_2 = { class: "text-xs-h5 text-sm-h6 pb-0 text-wrap" }
const _hoisted_3 = { class: "text-xs-h5 text-sm-h6 pl-1 pb-0 text-grey" }
const _hoisted_4 = {
  key: 0,
  class: "text-xs-h5 text-sm-h6 pl-1 pb-0 text-grey text-wrap"
}
const _hoisted_5 = {
  key: 1,
  class: "text-xs-h5 text-sm-h6 text-no-wrap text-grey pl-4 pb-0"
}
const _hoisted_6 = {
  key: 0,
  class: "d-flex flex-wrap pb-0 position-absolute justify-center mt-3",
  id: "price-legend",
  style: {"pointer-events":"none","left":"0","right":"0","margin-left":"auto","margin-right":"auto"}
}
const _hoisted_7 = {
  key: 0,
  id: "123"
}
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-space-between align-center mt-4"
}
const _hoisted_9 = {
  class: "text-body-1 text-no-wrap",
  style: {"user-select":"none"}
}
const _hoisted_10 = {
  class: "text-body-1",
  "_v-show": "getEntrySelectedCount(entry.segmentId, entry.price)",
  style: {"user-select":"none"}
}
const _hoisted_11 = {
  key: 1,
  class: "text-body-1"
}
const _hoisted_12 = {
  key: 1,
  class: "text-subtitle-1 py-2"
}
const _hoisted_13 = {
  key: 2,
  class: "pa-3 d-flex flex-column"
}
const _hoisted_14 = { class: "text-body-1" }
const _hoisted_15 = { class: "d-inline-flex align-center" }
const _hoisted_16 = { class: "text-body-1 mx-1" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "d-inline-flex" }
const _hoisted_19 = { class: "text-subtitle-1 font-weight-bold" }
const _hoisted_20 = {
  key: 0,
  class: "text-subtitle-1 font-weight-bold"
}
const _hoisted_21 = { class: "text-caption" }
const _hoisted_22 = { class: "text-caption" }
const _hoisted_23 = {
  key: 1,
  class: "text-subtitle-1"
}
const _hoisted_24 = {
  key: 2,
  class: "text-subtitle-1"
}
const _hoisted_25 = {
  key: 3,
  class: "text-subtitle-1"
}
const _hoisted_26 = {
  key: 4,
  class: "text-subtitle-1"
}
const _hoisted_27 = { class: "text-wrap pa-0 ma-0 text-caption text-left text-no-wrap" }
const _hoisted_28 = ["href"]
const _hoisted_29 = { key: 1 }
const _hoisted_30 = {
  key: 0,
  class: "text-subtitle-1 text-red-darken-1"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "text-subtitle-2" }
const _hoisted_33 = { class: "text-caption text-grey" }
const _hoisted_34 = {
  key: 0,
  class: "text-orange"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_slide_group_item = _resolveComponent("v-slide-group-item")!
  const _component_v_slide_group = _resolveComponent("v-slide-group")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "d-flex justify-center pa-0" }, {
    default: _withCtx(() => [
      (_ctx.pending)
        ? (_openBlock(), _createBlock(_component_v_sheet, {
            key: 0,
            class: "d-flex justify-center align-center",
            height: "400px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                "model-value": "20",
                size: "100",
                color: _ctx.primaryColor,
                indeterminate: ""
              }, null, 8, ["color"])
            ]),
            _: 1
          }))
        : (_ctx.eventData)
          ? (_openBlock(), _createBlock(_component_v_sheet, {
              key: 1,
              "max-width": "912px",
              width: "912px",
              "min-width": "375px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_window, {
                  modelValue: _ctx.step,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.step) = $event)),
                  disabled: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_window_item, {
                      value: 1,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.eventData.name), 1),
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.eventData.ageLimit), 1),
                          (_ctx.eventData.istEvent)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.eventData.desc), 1))
                            : _createCommentVNode("", true),
                          (!_ctx.eventData.istEvent)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.eventData.date), 1))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_spacer),
                          (_ctx.$route.query['debug'])
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 2,
                                onClick: _ctx.setSeatElementsId,
                                icon: "mdi-cog",
                                variant: "outlined",
                                size: "small",
                                class: "ma-0 pa-0"
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.$route.query['debug'])
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 3,
                                onClick: _ctx.calcTransforms,
                                icon: "mdi-cog",
                                variant: "outlined",
                                size: "small",
                                class: "ma-0 pa-0"
                              }, {
                                default: _withCtx(() => _cache[31] || (_cache[31] = [
                                  _createTextVNode("XY ")
                                ])),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.$route.query['debug'])
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 4,
                                onClick: _ctx.turnXY,
                                icon: "mdi-cog",
                                variant: "outlined",
                                size: "small",
                                class: "ma-0 pa-0"
                              }, {
                                default: _withCtx(() => _cache[32] || (_cache[32] = [
                                  _createTextVNode("-1 ")
                                ])),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.isIframe())
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 5,
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getTotalCount() ? _ctx.closeDialog=true : _ctx.close())),
                                icon: "mdi-close",
                                variant: "flat",
                                size: "small",
                                class: "ma-0 pa-0",
                                style: {"position":"absolute","right":"4px","top":"4px"}
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.svgHtml)
                          ? (_openBlock(), _createBlock(_component_v_sheet, {
                              key: 0,
                              class: "elevation-0",
                              height: _ctx.mobile ? (_ctx.getWindowHeight() - 230-60) +'px' : '440px'
                            }, {
                              default: _withCtx(() => [
                                (!(_ctx.pricesValues.length > 20 && _ctx.mobile))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pricesForLegend, (price) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, {
                                          key: price,
                                          onMouseover: ($event: any) => (_ctx.onHoverPrice(price)),
                                          onMouseleave: ($event: any) => (_ctx.onLeavePrice(price)),
                                          onClick: ($event: any) => (_ctx.onClickPrice(price)),
                                          class: "mr-2 mb-1 font-weight-bold",
                                          size: "small",
                                          variant: "text",
                                          style: _normalizeStyle({
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                      boxShadow: '#00000020 0px 2px 8px 0px',
                      background: 'white',
                      color: ''+_ctx.pricesColors[_ctx.pricesValues.indexOf(price)]
                    })
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.formatMoney(price)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onMouseover", "onMouseleave", "onClick", "style"]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                _cache[1] || (
                                  _setBlockTracking(-1),
                                  (_cache[1] = _createVNode(_component_v_sheet, {
                                    innerHTML: _ctx.svgHtml,
                                    class: "pa-0",
                                    onTouchstart: _ctx.onSvgMapTouchStart,
                                    onMousedown: _ctx.onSvgMapMouseDown,
                                    onMouseup: _ctx.onSvgMapMouseUp,
                                    onTouchend: _ctx.onSvgMapClick,
                                    onClick: _ctx.onSvgMapClick,
                                    _mouseup: "onSvgMapMouseUp",
                                    height: "100%",
                                    "__max-height": "600px",
                                    style: {"border-width":"0"}
                                  }, null, 8, ["innerHTML", "onTouchstart", "onMousedown", "onMouseup", "onTouchend", "onClick"])).cacheIndex = 1,
                                  _setBlockTracking(1),
                                  _cache[1]
                                ),
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-plus",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zoom(true))),
                                  disabled: _ctx.zoomPlusDisabled,
                                  class: "mb-1 elevation-2 position-absolute",
                                  style: {"top":"240px","right":"2%","pointer-events":"all"}
                                }, null, 8, ["disabled"]),
                                _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_component_v_btn, {
                                  icon: "mdi-minus",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.zoom(false))),
                                  disabled: _ctx.zoomMinusDisabled,
                                  class: "elevation-2 position-absolute",
                                  style: {"top":"300px","right":"2%","pointer-events":"all"}
                                }, null, 8, ["disabled"]),
                                (_ctx.mobile && _ctx.getTotalCount() > 0)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 1,
                                      color: _ctx.primaryColor,
                                      class: "font-weight-bold align-self-center elevation-4",
                                      style: {"bottom":"150px","right":"2%","pointer-events":"all","position":"absolute"},
                                      variant: "flat",
                                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.step=2))
                                    }, {
                                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                                        _createTextVNode(" Далее ")
                                      ])),
                                      _: 1
                                    }, 8, ["color"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["height"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_dialog, {
                          modelValue: _ctx.dialogEntryTickets,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialogEntryTickets) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, {
                              "min-width": "400px",
                              "max-width": "400px",
                              class: "pa-2 align-center align-self-center"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.eventData.entryTickets && _ctx.eventData.entryTickets.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventData.entryTickets, (entry) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: entry.id
                                        }, [
                                          (!_ctx.dialogEntryTicketsSegment || (_ctx.dialogEntryTicketsSegment && entry.segmentId.toString() == _ctx.dialogEntryTicketsSegment) )
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                _createElementVNode("div", _hoisted_9, [
                                                  _createTextVNode(_toDisplayString(entry.segmentName) + " ", 1),
                                                  _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.formatMoney(entry.price)), 1)
                                                ]),
                                                _createVNode(_component_v_spacer),
                                                _createVNode(_component_v_spacer),
                                                _createVNode(_component_v_btn, {
                                                  size: "x-small",
                                                  variant: "tonal",
                                                  icon: "mdi-minus",
                                                  class: "ma-1",
                                                  onClick: ($event: any) => (_ctx.delEntryToSelected(entry.id)),
                                                  disabled: !_ctx.getEntrySelectedCount(entry.segmentId, entry.price)
                                                }, null, 8, ["onClick", "disabled"]),
                                                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getEntrySelectedCount(entry.segmentId, entry.price)), 1),
                                                _createVNode(_component_v_btn, {
                                                  size: "x-small",
                                                  variant: "tonal",
                                                  icon: "mdi-plus",
                                                  class: "ma-1",
                                                  onClick: ($event: any) => (_ctx.addEntryToSelected(entry))
                                                }, null, 8, ["onClick"])
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      }), 128))
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_11, "Нет билетов")),
                                _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_component_v_card_actions, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      color: _ctx.primaryColor,
                                      class: "font-weight-bold",
                                      variant: "flat",
                                      onClick: _cache[5] || (_cache[5] = ()=>{_ctx.dialogEntryTickets=false ;_ctx.dialogEntryTicketsSegment=''})
                                    }, {
                                      default: _withCtx(() => _cache[36] || (_cache[36] = [
                                        _createTextVNode("OK ")
                                      ])),
                                      _: 1
                                    }, 8, ["color"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"]),
                        (!Object.keys(_ctx.eventData.seats??{}).length && (!_ctx.eventData.entryTickets || !_ctx.eventData.entryTickets.length))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[38] || (_cache[38] = [
                              _createTextVNode(" На данный момент свободных мест нет,"),
                              _createElementVNode("br", null, null, -1),
                              _createTextVNode("пожалуйста попробуйте позже. ")
                            ])))
                          : _createCommentVNode("", true),
                        (_ctx.eventData.istEvent)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventData.entryTickets, (entry) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: entry.id,
                                  class: "d-inline-flex text-no-wrap justify-end align-center"
                                }, [
                                  _createElementVNode("div", _hoisted_14, _toDisplayString(entry.segmentName), 1),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formatMoney(entry.price)), 1),
                                    _createVNode(_component_v_spacer),
                                    _withDirectives(_createVNode(_component_v_btn, {
                                      size: "small",
                                      variant: "tonal",
                                      icon: "mdi-minus",
                                      class: "ma-1",
                                      onClick: ($event: any) => (_ctx.delEntryToSelected(entry.id))
                                    }, null, 8, ["onClick"]), [
                                      [_vShow, _ctx.getEntrySelectedCount(entry.segmentId, entry.price)]
                                    ]),
                                    _withDirectives(_createElementVNode("div", { class: "text-body-1" }, _toDisplayString(_ctx.getEntrySelectedCount(entry.segmentId, entry.price)), 513), [
                                      [_vShow, _ctx.getEntrySelectedCount(entry.segmentId, entry.price)]
                                    ]),
                                    _createVNode(_component_v_btn, {
                                      size: "small",
                                      variant: "tonal",
                                      icon: "mdi-plus",
                                      class: "ma-1",
                                      onClick: ($event: any) => (_ctx.addEntryToSelected(entry))
                                    }, null, 8, ["onClick"])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.getTotalCount() > 0)
                          ? (_openBlock(), _createBlock(_component_v_card, {
                              key: 3,
                              class: _normalizeClass(["d-flex justify-space-between elevation-0", _ctx.mobile ? 'flex-column' : '']),
                              "max-height": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_slide_group, {
                                  class: "pa-0 d-flex",
                                  "show-arrows": ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected.seats, (seatId) => {
                                      return (_openBlock(), _createBlock(_component_v_slide_group_item, { key: seatId }, {
                                        default: _withCtx(({ toggle }) => [
                                          (_ctx.eventData.seats)
                                            ? (_openBlock(), _createBlock(_component_v_card, {
                                                key: 0,
                                                _link: "",
                                                width: "138",
                                                "max-width": "138",
                                                "max-height": "90",
                                                elevation: "0",
                                                class: "bg-grey-lighten-4 mt-3 mx-2 mb-1 text-left",
                                                style: {"cursor":"pointer","user-select":"none"},
                                                onClick: ($event: any) => {toggle(); _ctx.onClickTicketTab(seatId)},
                                                onMouseover: ($event: any) => (_ctx.onHoverSelected(seatId)),
                                                onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onLeaveSelected()))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_btn, {
                                                    variant: "text",
                                                    class: "ma-0 pa-0 position-absolute align-self-end text-grey",
                                                    style: {"left":"116px","top":"2px"},
                                                    density: "compact",
                                                    size: "small",
                                                    icon: "mdi-close",
                                                    onClick: ($event: any) => (_ctx.removeSeatFromSelected(seatId))
                                                  }, null, 8, ["onClick"]),
                                                  _createVNode(_component_v_card_text, { class: "text-body-1 text-no-wrap pt-2 pb-1" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.eventData.seats[seatId].price)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_card_subtitle, { class: "text-body-2 text-no-wrap" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.eventData.seats[seatId].segmentName), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  (_ctx.eventData.seats[seatId].isBadView)
                                                    ? (_openBlock(), _createBlock(_component_v_card_text, {
                                                        key: 0,
                                                        class: "text-caption text-no-wrap text-orange pb-0 pt-0"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.eventData.seats[seatId].seatName), 1),
                                                          _cache[39] || (_cache[39] = _createElementVNode("br", null, null, -1)),
                                                          _createTextVNode(_toDisplayString(_ctx.eventData.badViewText), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    : (_openBlock(), _createBlock(_component_v_card_text, {
                                                        key: 1,
                                                        class: "text-body-2 text-no-wrap pb-0 pt-1"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.eventData.seats[seatId].seatName), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick", "onMouseover"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected.entry, (entry) => {
                                      return (_openBlock(), _createBlock(_component_v_slide_group_item, {
                                        key: entry.id
                                      }, {
                                        default: _withCtx(({ toggle }) => [
                                          (_ctx.eventData.seats)
                                            ? (_openBlock(), _createBlock(_component_v_card, {
                                                key: 0,
                                                _link: "",
                                                width: "138",
                                                "max-width": "138",
                                                "max-height": "90",
                                                class: "bg-grey-lighten-5 mt-3 mx-2 mb-1 text-left",
                                                style: {"cursor":"pointer","user-select":"none"},
                                                onClick: ($event: any) => {toggle(); _ctx.onClickTicketTab(''+entry.id)},
                                                onMouseover: ($event: any) => (_ctx.onHoverSelected(''+entry.id)),
                                                onMouseleave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onLeaveSelected()))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_btn, {
                                                    variant: "flat",
                                                    class: "ma-0 pa-0 position-absolute align-self-end text-grey",
                                                    style: {"left":"116px","top":"2px"},
                                                    density: "compact",
                                                    size: "small",
                                                    icon: "mdi-close",
                                                    onClick: ($event: any) => (_ctx.removeSeatFromSelected(''+entry.id))
                                                  }, null, 8, ["onClick"]),
                                                  _createVNode(_component_v_card_text, { class: "text-body-1 text-no-wrap pt-2 pb-1" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(entry.price)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_card_subtitle, { class: "text-body-2 text-no-wrap" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(entry.segmentName), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick", "onMouseover"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_spacer),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["pt-2 justify-end", _ctx.mobile ? 'd-flex flex-row' : ''])
                                }, [
                                  _createVNode(_component_v_card_title, { class: "text-xs-h3 text-md-h5 mt-1 pa-0 align-self-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.formatMoney(_ctx.getTotalSum())) + " " + _toDisplayString(_ctx.mobile ? ', билетов: ' + _ctx.getTotalCount() : ''), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _withDirectives(_createVNode(_component_v_btn, {
                                    color: _ctx.primaryColor,
                                    class: "font-weight-bold ma-3 align-self-center",
                                    variant: "flat",
                                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.step=2))
                                  }, {
                                    default: _withCtx(() => _cache[40] || (_cache[40] = [
                                      _createTextVNode("Далее ")
                                    ])),
                                    _: 1
                                  }, 8, ["color"]), [
                                    [_vShow, !_ctx.mobile]
                                  ])
                                ], 2)
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 2,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _cache[48] || (_cache[48] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _cache[41] || (_cache[41] = _createTextVNode("Ваши данные ")),
                            _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode("для получения " + _toDisplayString(_ctx.eventData.ticketNameOf.toLowerCase()), 1)
                          ]),
                          _: 1
                        }),
                        _cache[49] || (_cache[49] = _createElementVNode("br", null, null, -1)),
                        _cache[50] || (_cache[50] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_sheet, {
                          width: "320px",
                          class: "v-container justify-center align-center align-self-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_form, {
                              onSubmit: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"])),
                              ref: "clientForm",
                              class: "pa-0"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.client.name,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.client.name) = $event)),
                                  rules: _ctx.nameRules,
                                  label: "Имя",
                                  name: "name",
                                  type: "text",
                                  variant: "underlined"
                                }, null, 8, ["modelValue", "rules"]),
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.client.email,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.client.email) = $event)),
                                  rules: _ctx.emailRules,
                                  label: `E-mail для получения ${_ctx.eventData.ticketNameOf.toLowerCase()}`,
                                  type: "email",
                                  name: "email",
                                  variant: "underlined"
                                }, null, 8, ["modelValue", "rules", "label"]),
                                (_ctx.eventData.collectTel)
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 0,
                                      modelValue: _ctx.client.tel,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.client.tel) = $event)),
                                      onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.client.tel = _ctx.client.tel.replace('+7',''))),
                                      rules: _ctx.telRules,
                                      prefix: "+7",
                                      label: "Телефон",
                                      type: "tel",
                                      name: "tel",
                                      variant: "underlined"
                                    }, null, 8, ["modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.eventData.collectBirthDate)
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 1,
                                      _type: "date",
                                      modelValue: _ctx.client.birthDate,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.client.birthDate) = $event)),
                                      rules: _ctx.dateRules,
                                      onInput: _ctx.onInputBirthDate,
                                      label: "Дата рождения",
                                      variant: "underlined",
                                      required: ""
                                    }, null, 8, ["modelValue", "rules", "onInput"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 512),
                            _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
                            _createVNode(_component_v_container, { class: "d-flex justify-space-between" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  class: "",
                                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.step=1)),
                                  variant: "tonal"
                                }, {
                                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                                    _createTextVNode(" Назад ")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: _ctx.primaryColor,
                                  _disabled: "client.error",
                                  type: "submit",
                                  class: "font-weight-bold",
                                  variant: "flat",
                                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onSubmitClientForm()))
                                }, {
                                  default: _withCtx(() => _cache[44] || (_cache[44] = [
                                    _createTextVNode(" Далее ")
                                  ])),
                                  _: 1
                                }, 8, ["color"])
                              ]),
                              _: 1
                            }),
                            _cache[47] || (_cache[47] = _createElementVNode("br", null, null, -1)),
                            (0 && _ctx.eventData.conditionsUrl)
                              ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                                  key: 0,
                                  class: "text-wrap justify-end"
                                }, {
                                  default: _withCtx(() => [
                                    _cache[45] || (_cache[45] = _createTextVNode(" Нажимая «Далее», вы соглашаетесь с ")),
                                    _createElementVNode("a", {
                                      href: _ctx.eventData.conditionsUrl,
                                      target: "_blank",
                                      style: {"color":"black"}
                                    }, " Условиями приобретения и Офертой", 8, _hoisted_17)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 3,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_v_sheet, {
                            width: "320px",
                            class: "d-flex flex-column align-center"
                          }, {
                            default: _withCtx(() => [
                              _cache[61] || (_cache[61] = _createElementVNode("br", null, null, -1)),
                              _createVNode(_component_v_card_title, { class: "pt-0 pb-0" }, {
                                default: _withCtx(() => _cache[51] || (_cache[51] = [
                                  _createTextVNode("Подтверждение заказа")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_container, { class: "d-flex flex-column justify-space-between" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_text, { class: "d-flex flex-column justify-start pt-2 pb-0 text-left" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.eventData.name), 1),
                                      (!_ctx.eventData.istEvent)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.eventData.date), 1))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.eventData.venueName), 1),
                                      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.eventData.venueAddress), 1),
                                      _cache[52] || (_cache[52] = _createElementVNode("br", null, null, -1)),
                                      (_ctx.client.name)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.client.name), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.client.birthDate)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, "Дата рождения: " + _toDisplayString(_ctx.client.birthDate), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.client.tel)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.client.tel), 1))
                                        : _createCommentVNode("", true),
                                      (_ctx.client.email)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.client.email), 1))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_expansion_panels, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_expansion_panel, { class: "elevation-0" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_expansion_panel_title, { class: "text-body-1 font-weight-bold text-left" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.eventData.ticketNameOf) + ": " + _toDisplayString(_ctx.getTotalCount()), 1)
                                            ]),
                                            _: 1
                                          }),
                                          (_ctx.eventData.seats)
                                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.selected.seats, (seatId) => {
                                                return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                                                  key: seatId,
                                                  class: "text-body-2 text-left"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.eventData.seats[seatId].segmentName) + " " + _toDisplayString(_ctx.eventData.seats[seatId].seatName) + " ", 1),
                                                    (_ctx.eventData.seats[seatId].isBadView)
                                                      ? (_openBlock(), _createBlock(_component_v_card_text, {
                                                          key: 0,
                                                          class: "text-orange pa-0 ma-0"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.eventData.badViewText), 1)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.eventData.seats[seatId].seatDesc) + " " + _toDisplayString(_ctx.formatMoney(Math.round(_ctx.eventData.seats[seatId].price * _ctx.getSegmentDiscountK(_ctx.eventData.seats[seatId].segmentId)))) + " " + _toDisplayString(_ctx.segmentsDiscounts && _ctx.segmentsDiscounts[_ctx.eventData.seats[seatId].segmentId] ? 'скидка ' + _ctx.segmentsDiscounts[_ctx.eventData.seats[seatId].segmentId] + '%' : ''), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              }), 128))
                                            : _createCommentVNode("", true),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected.entry, (entry) => {
                                            return (_openBlock(), _createBlock(_component_v_expansion_panel_text, {
                                              key: entry.id,
                                              class: "text-body-1"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(entry.segmentName) + ", " + _toDisplayString(_ctx.formatMoney(Math.round(entry.price * _ctx.getSegmentDiscountK(entry.segmentId)))) + " " + _toDisplayString(_ctx.segmentsDiscounts && _ctx.segmentsDiscounts[entry.segmentId] ? 'скидка ' + _ctx.segmentsDiscounts[entry.segmentId] + '%' : ''), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_text, { class: "text-body-1 font-weight-bold text-no-wrap text-left pt-2 pb-0 mr-0 pr-0" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.segmentsDiscounts ? 'Стоимость со скидкой' : 'Итоговая стоимость:') + " " + _toDisplayString(_ctx.formatMoney(_ctx.getTotalSum())), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_dialog, {
                                    modelValue: _ctx.dialogPromo,
                                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.dialogPromo) = $event)),
                                    width: "auto"
                                  }, {
                                    activator: _withCtx(({ props }) => [
                                      (_ctx.eventData.enablePromocode && !_ctx.segmentsDiscounts)
                                        ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                                            class: "my-2",
                                            color: _ctx.primaryColor,
                                            variant: "tonal"
                                          }), {
                                            default: _withCtx(() => _cache[53] || (_cache[53] = [
                                              _createTextVNode(" Ввести промокод % ")
                                            ])),
                                            _: 2
                                          }, 1040, ["color"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, {
                                        "max-width": "400",
                                        "min-width": "320",
                                        loading: _ctx.dialogPromoPending ? _ctx.primaryColor : false,
                                        disabled: _ctx.dialogPromoPending
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            focused: "",
                                            clearable: "",
                                            autofocus: "",
                                            modelValue: _ctx.promoCode,
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.promoCode) = $event)),
                                            "error-messages": _ctx.promoCodeError,
                                            label: "Промокод",
                                            variant: "outlined",
                                            class: "pa-4 pb-0 font-weight-bold",
                                            onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.promoCode = _ctx.promoCode.toUpperCase())),
                                            color: _ctx.primaryColor
                                          }, null, 8, ["modelValue", "error-messages", "color"]),
                                          _createVNode(_component_v_card_actions, { class: "justify-center my-2 mx-2" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                onClick: _cache[20] || (_cache[20] = ($event: any) => {_ctx.dialogPromo = false; _ctx.promoCode = ''; _ctx.promoCodeError = ''}),
                                                variant: "tonal"
                                              }, {
                                                default: _withCtx(() => _cache[54] || (_cache[54] = [
                                                  _createTextVNode("Отмена ")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_spacer),
                                              _createVNode(_component_v_btn, {
                                                color: _ctx.primaryColor,
                                                class: "font-weight-bold",
                                                onClick: _ctx.applyPromoCode,
                                                variant: "flat"
                                              }, {
                                                default: _withCtx(() => _cache[55] || (_cache[55] = [
                                                  _createTextVNode(" Применить ")
                                                ])),
                                                _: 1
                                              }, 8, ["color", "onClick"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["loading", "disabled"])
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]),
                                  (_ctx.eventData.conditionsUrl)
                                    ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                        key: 0,
                                        modelValue: _ctx.conditionsAgree,
                                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.conditionsAgree) = $event)),
                                        class: "pa-0 ma-0",
                                        "hide-details": "",
                                        error: _ctx.conditionsAlarm && !_ctx.conditionsAgree
                                      }, {
                                        label: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_27, [
                                            _cache[56] || (_cache[56] = _createTextVNode(" Соглсаен с ")),
                                            (_ctx.eventData?.conditionsUrl)
                                              ? (_openBlock(), _createElementBlock("a", {
                                                  key: 0,
                                                  href: _ctx.eventData?.conditionsUrl,
                                                  target: "_blank",
                                                  style: {"color":"black"}
                                                }, "Условиями приобретения и Офертой", 8, _hoisted_28))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_29, "Условиями приобретения и Офертой"))
                                          ])
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue", "error"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_v_btn, {
                                    color: _ctx.primaryColor,
                                    class: "mt-0 mb-2 font-weight-bold",
                                    variant: "flat",
                                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.createOrder(false)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getTotalSum() > 0 ? 'Перейти к оплате' : 'Оформить Заказ'), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["color"]),
                                  (_ctx.eventData.sbpAvailable)
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 1,
                                        color: "",
                                        class: "my-2 font-weight-bold bg-green-lighten-5",
                                        variant: "flat",
                                        onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.createOrder(true)))
                                      }, {
                                        default: _withCtx(() => _cache[57] || (_cache[57] = [
                                          _createTextVNode(" Оплатить "),
                                          _createElementVNode("img", {
                                            src: "sbp_logo2.png",
                                            height: "32",
                                            alt: "СБП"
                                          }, null, -1)
                                        ])),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.eventData.legalInfo)
                                    ? (_openBlock(), _createBlock(_component_v_dialog, {
                                        key: 2,
                                        modelValue: _ctx.dialogSellerInfo,
                                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.dialogSellerInfo) = $event)),
                                        width: "auto"
                                      }, {
                                        activator: _withCtx(({ props }) => [
                                          _createVNode(_component_v_card_subtitle, _mergeProps(props, { style: {"cursor":"pointer"} }), {
                                            default: _withCtx(() => _cache[58] || (_cache[58] = [
                                              _createTextVNode(" Информация о продавце ")
                                            ])),
                                            _: 2
                                          }, 1040)
                                        ]),
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, { "max-width": "340" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_card_text, {
                                                innerHTML: _ctx.eventData.legalInfo.replace(/\n/g, '<br/>')
                                              }, null, 8, ["innerHTML"]),
                                              _createVNode(_component_v_card_actions, { class: "justify-center" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_btn, {
                                                    variant: "tonal",
                                                    onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.dialogSellerInfo = false))
                                                  }, {
                                                    default: _withCtx(() => _cache[59] || (_cache[59] = [
                                                      _createTextVNode("Закрыть")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.step=2)),
                                    class: "my-3",
                                    variant: "tonal"
                                  }, {
                                    default: _withCtx(() => _cache[60] || (_cache[60] = [
                                      _createTextVNode("Назад")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 4,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _cache[63] || (_cache[63] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[62] || (_cache[62] = [
                            _createTextVNode("Резервирование...")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_sheet, {
                          width: "320px",
                          class: "d-inline-flex flex-column justify-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_form, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_progress_circular, {
                                  class: "mt-12",
                                  "model-value": "20",
                                  size: "100",
                                  color: _ctx.primaryColor,
                                  indeterminate: ""
                                }, null, 8, ["color"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 5,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        (_ctx.orderData)
                          ? (_openBlock(), _createBlock(_component_v_sheet, {
                              key: 0,
                              width: "320px",
                              class: "d-inline-flex flex-column justify-start"
                            }, {
                              default: _withCtx(() => [
                                _cache[69] || (_cache[69] = _createElementVNode("br", null, null, -1)),
                                _cache[70] || (_cache[70] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_component_v_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Заказ " + _toDisplayString(_ctx.orderData.orderId), 1),
                                    _cache[64] || (_cache[64] = _createElementVNode("br", null, null, -1)),
                                    _cache[65] || (_cache[65] = _createTextVNode("успешно зарезервирован."))
                                  ]),
                                  _: 1
                                }),
                                _cache[71] || (_cache[71] = _createElementVNode("br", null, null, -1)),
                                (0)
                                  ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                                      default: _withCtx(() => _cache[66] || (_cache[66] = [
                                        _createTextVNode(" Пожалуйста завершите оплату заказа на странице платежной системы. ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (_ctx.orderData.paySeconds)
                                  ? (_openBlock(), _createBlock(_component_v_card_title, {
                                      key: 1,
                                      color: _ctx.orderData.paySeconds < 30 ? 'red' : ''
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Время резерва " + _toDisplayString(_ctx.formatTimer(_ctx.orderData.paySeconds)), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["color"]))
                                  : _createCommentVNode("", true),
                                _cache[72] || (_cache[72] = _createElementVNode("br", null, null, -1)),
                                _createVNode(_component_v_btn, {
                                  href: _ctx.orderData.payUrl,
                                  target: "_blank",
                                  class: "my-0 font-weight-bold",
                                  color: _ctx.primaryColor,
                                  variant: "flat"
                                }, {
                                  default: _withCtx(() => _cache[67] || (_cache[67] = [
                                    _createTextVNode(" Перейти к оплате ")
                                  ])),
                                  _: 1
                                }, 8, ["href", "color"]),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => _cache[68] || (_cache[68] = [
                                    _createTextVNode(" Вы будете перенаправлены на страницу платежной системы: ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 6,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _cache[77] || (_cache[77] = _createElementVNode("br", null, null, -1)),
                        _cache[78] || (_cache[78] = _createElementVNode("br", null, null, -1)),
                        _cache[79] || (_cache[79] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_icon, {
                          class: "mb-5",
                          color: "success",
                          icon: "mdi-check-circle",
                          size: "112"
                        }),
                        _cache[80] || (_cache[80] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[73] || (_cache[73] = [
                            _createTextVNode("Успешная покупка!")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_sheet, {
                          width: "320px",
                          class: "d-inline-flex flex-column justify-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_form, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Ваши " + _toDisplayString(_ctx.eventData.ticketNameMany.toLowerCase()) + " отправлены на почту " + _toDisplayString(_ctx.client.email), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_btn, { onClick: _ctx.close }, {
                                  default: _withCtx(() => _cache[74] || (_cache[74] = [
                                    _createTextVNode(" Вернуться в Афишу ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _cache[75] || (_cache[75] = _createElementVNode("br", null, null, -1)),
                                _cache[76] || (_cache[76] = _createElementVNode("br", null, null, -1))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_window_item, {
                      value: 7,
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _cache[86] || (_cache[86] = _createElementVNode("br", null, null, -1)),
                        _cache[87] || (_cache[87] = _createElementVNode("br", null, null, -1)),
                        _cache[88] || (_cache[88] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_icon, {
                          class: "mb-5",
                          color: "amber-lighten-3",
                          icon: "mdi-close-circle-outline",
                          size: "112"
                        }),
                        _cache[89] || (_cache[89] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[81] || (_cache[81] = [
                            _createTextVNode("Что-то пошло не так")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_sheet, {
                          width: "320px",
                          class: "d-inline-flex flex-column justify-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_form, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.errorText), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_btn, { onClick: _ctx.close }, {
                                  default: _withCtx(() => _cache[82] || (_cache[82] = [
                                    _createTextVNode(" Вернуться в Афишу ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _cache[83] || (_cache[83] = _createElementVNode("br", null, null, -1)),
                                _cache[84] || (_cache[84] = _createElementVNode("br", null, null, -1)),
                                _cache[85] || (_cache[85] = _createElementVNode("br", null, null, -1))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.seatOver && !_ctx.isMapPanned)
                  ? (_openBlock(), _createBlock(_component_v_sheet, {
                      key: 0,
                      rounded: "",
                      border: "",
                      ref: "seatTooltip",
                      class: "elevation-4 pa-2",
                      style: _normalizeStyle([{"position":"absolute","pointer-events":"none"}, _ctx.seatTooltipStyle])
                    }, {
                      default: _withCtx(() => [
                        (_ctx.getTotalCount() >= _ctx.getTicketsLimit())
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, " Не более " + _toDisplayString(_ctx.getTicketsLimit()) + " в один заказ ", 1))
                          : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.formatMoney(_ctx.seatOver.price)), 1),
                              _createElementVNode("div", _hoisted_33, [
                                _createTextVNode(_toDisplayString(_ctx.seatOver.segmentName) + " ", 1),
                                _cache[90] || (_cache[90] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(_toDisplayString(_ctx.seatOver.seatName) + " ", 1),
                                _cache[91] || (_cache[91] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(_toDisplayString(_ctx.seatOver.seatDesc) + " ", 1),
                                (_ctx.seatOver.isBadView)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(_ctx.eventData.badViewText), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]))
                      ]),
                      _: 1
                    }, 8, ["style"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_dialog, {
                  modelValue: _ctx.closeDialog,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.closeDialog) = $event)),
                  width: "auto"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      "max-width": "340",
                      class: "pt-2 px-6 pb-6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "ma-2" }, {
                          default: _withCtx(() => _cache[92] || (_cache[92] = [
                            _createTextVNode("Вы точно хотите выйти?")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          color: _ctx.primaryColor,
                          variant: "flat",
                          onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.closeDialog=false)),
                          class: "font-weight-bold"
                        }, {
                          default: _withCtx(() => _cache[93] || (_cache[93] = [
                            _createTextVNode("Продолжить покупку ")
                          ])),
                          _: 1
                        }, 8, ["color"]),
                        _cache[95] || (_cache[95] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.close,
                          variant: "tonal"
                        }, {
                          default: _withCtx(() => _cache[94] || (_cache[94] = [
                            _createTextVNode("Выйти")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_v_sheet, {
              key: 2,
              class: "ma-16",
              height: "400px"
            }, {
              default: _withCtx(() => [
                (_ctx.eventData===null)
                  ? (_openBlock(), _createBlock(_component_v_card_title, {
                      key: 0,
                      class: "mx-10"
                    }, {
                      default: _withCtx(() => _cache[96] || (_cache[96] = [
                        _createTextVNode("Мероприятие не найдено"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode("или уже прошло")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_card_title, {
                      key: 1,
                      class: "mx-10"
                    }, {
                      default: _withCtx(() => _cache[97] || (_cache[97] = [
                        _createTextVNode("Сервис временно недоступен"),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode("Попробуйте позже")
                      ])),
                      _: 1
                    })),
                _cache[99] || (_cache[99] = _createElementVNode("br", null, null, -1)),
                _cache[100] || (_cache[100] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_v_btn, {
                  color: _ctx.primaryColor,
                  variant: "flat",
                  class: "font-weight-bold",
                  onClick: _ctx.close
                }, {
                  default: _withCtx(() => _cache[98] || (_cache[98] = [
                    _createTextVNode(" Вернуться в афишу ")
                  ])),
                  _: 1
                }, 8, ["color", "onClick"])
              ]),
              _: 1
            }))
    ]),
    _: 1
  }))
}