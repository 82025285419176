export const originsConf: { [key: string]: { color?: string, ym?: { [key: string]: unknown } } } = {

    'undefined-site': {},

    'vegas-hall.ru': {
        color: '#ee1122',
        ym: {
            id: '39017335',
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            ecommerce: "dataLayer",
            //webvisor: true,
            //trackHash: true
        }
    },

    // 'undefined-site': {
    //     color: '#ee1122'
    // },
    // live-arena.ru '7d29311156acc419804a2377df2c7163'
    'livearena.ru': {
        color: '#ee1122'
    },

    'stas-mihaylov.ru': {
        color: '#f6c769',
        ym: {
            id: '99860771',
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            ecommerce: "dataLayer",
            //webvisor: true,
            //trackHash: true
        }
    },

    'chumakoff.ru': {
        color: '#cb1c1c'
    }


}
